import { UpdateProductLayout } from "component/product/update";
import { RouteConstant } from "navigation/constant";
import { useParams, Navigate } from "react-router-dom";

export const UpdateProduct = () => {
  const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.SERVICE} />;
  return (
    <>
      <UpdateProductLayout id={params.id} />
    </>
  );
};
