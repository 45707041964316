import { GetAllProductsProps, UpdateProductProps } from "_interfaces/functions/http-requests/product";
import {
  GET_ALL_PRODUCT,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const GetAllProduct = ({ DATA }: GetAllProductsProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_PRODUCT,
    Data: DATA,
  });
};

export const GetProductById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_PRODUCT_BY_ID(ID),
  });
};

export const UpdateProduct = ({ DATA }: UpdateProductProps) => {
  return HTTP({
    Method: "POST",
    Url: UPDATE_PRODUCT,
    Data: DATA,
  });
};
