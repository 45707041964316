import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { UploadMsisdnProps } from "_interfaces/functions/http-requests/msisdnManagement";
import { UploadMsisdnModel } from "_models/data/msisdnManagement/data.upload-msisdn-bulk.model";
import { UploadMsisdnErrorModel } from "_models/error/msisdnManagement/error.upload-msisdn-bulk.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { UploadMsisdn } from "functions/http-requests/msisdnManagement";
import {
  isValidString,
  renderInvalidStringErrorText,
} from "functions/validators";
import { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { validateUploadMsisdn } from "./formValidator";
import axios from "axios";
import { generateAutocompleteOptions } from "functions/helper";

interface InputListProps extends FormInputProps {
  name: "batchNo" | "firstMSISDN" | "lastMSISDN" | "type" | "category" | "MVNO";
}

interface Props {
  editId?: string;
  handleRefresh?: () => void;
  onClose?: () => void;
}

const UploadMsisdnForm: React.FC<Props> = ({
  editId,
  handleRefresh,
  onClose,
}) => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<UploadMsisdnModel>(
    new UploadMsisdnModel()
  );
  const [errors, setErrors] = useState<UploadMsisdnErrorModel>(
    new UploadMsisdnErrorModel()
  );

  const [editRefresh, setEditRefresh] = useState<boolean>(false);

  const handleEditRefresh = (REFRESH: boolean) => setEditRefresh(REFRESH);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      if (name === "name" && value) {
        if (!isValidString(value)) {
          return setErrors((prev) => ({
            ...prev,
            [name]: renderInvalidStringErrorText(),
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
      }
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCreate = ({ DATA }: UploadMsisdnProps) => {
    Dispatch(toggleLoading(true));
    UploadMsisdn({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          if (onClose) onClose();
          if (handleRefresh) handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateUploadMsisdn(state);

    if (ValidateStep?.valid) {
      let DATA: UploadMsisdnProps["DATA"] = {
        batchNo: state.batchNo,
        firstMSISDN: state.firstMSISDN,
        lastMSISDN: state.lastMSISDN,
        type: state.type,
        category: state.category,
        MVNO: state.MVNO,
      };
      handleCreate({
        DATA: DATA,
      });
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "text",
        name: "batchNo",
        label: "Batch No",
        placeholder: "Enter Batch No",
        grid: 12,
        enabled: true,
      },
      {
        type: "number",
        name: "firstMSISDN",
        label: "First MSISDN",
        placeholder: "Enter First MSISDN",
        grid: 6,
        enabled: true,
      },
      {
        type: "number",
        name: "lastMSISDN",
        label: "Last MSISDN",
        placeholder: "Enter Last MSISDN",
        grid: 6,
        enabled: true,
      },
      {
        type: "autoComplete",
        name: "type",
        label: "Type",
        placeholder: "Select Type",
        multiple: false,
        grid: 6,
        enabled: true,
        options: [
          {
            id: "normal",
            value: "normal",
            title: "Normal",
            isActive: true,
          },
          {
            id: "premium",
            value: "premium",
            title: "Premium",
            isActive: true,
          },
        ],
      },
      {
        type: "autoComplete",
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        multiple: false,
        grid: 6,
        enabled: true,
        options: generateAutocompleteOptions([
          "SIP",
          "normal",
          "toll_free",
          "fixed",
          "broadband",
        ]),
      },
      {
        type: "autoComplete",
        name: "MVNO",
        label: "MVNO",
        placeholder: "Select MVNO",
        multiple: false,
        grid: 6,
        enabled: true,
        options: generateAutocompleteOptions([
          "TalkioMobile",
          "PatriotMobile",
          "Fast",
          "HFBConnect",
          "Simple",
        ]),
      },
    ],
    [editId]
  );

  return (
    <>
      <Box id="upload_msisdn_form" component="form" onSubmit={handleSubmit}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                enabled,
                options,
                multiple,
              },
              index
            ) => (
              <Fragment key={index}>
                {enabled ? (
                  <>
                    {type === "text" ||
                    type === "email" ||
                    type === "number" ? (
                      <Grid item xs={12} md={6} lg={grid || 6}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof UploadMsisdnModel]}
                          errorText={
                            errors[name as keyof UploadMsisdnErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                        />
                      </Grid>
                    ) : type === "autoComplete" ? (
                      <Grid item xs={12} md={6} lg={grid || 6}>
                        <InputAutoComplete
                          name={name}
                          options={options || []}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options.length &&
                            state?.[name as keyof UploadMsisdnModel]
                          }
                          errorText={
                            errors[name as keyof UploadMsisdnErrorModel]
                          }
                          onFocus={handleFocus}
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              Upload MSISDN
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UploadMsisdnForm;
