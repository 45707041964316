import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ChargesTableInterface from "_models/data/charges/data.charges.model";
import { RouteConstant } from "navigation/constant";
import { Visibility } from "@mui/icons-material";

const ChargesTable: React.FC<ChargesTableInterface> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "chargeName",
      headerName: "Charge Name",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params.value}</Box>
      ),
    },
    {
      field: "charges",
      headerName: "Charges",
      flex: 1,
      maxWidth: 130,
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return Number(params.value).toLocaleString();
      },
    },
    {
      field: "subTotal",
      headerName: "Sub Total",
      flex: 1,
      maxWidth: 130,
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return Number(params.value).toLocaleString();
      },
    },
    {
      field: "serviceCharges",
      headerName: "Service Charges",
      flex: 1,
      maxWidth: 180,
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return Number(params.value).toLocaleString();
      },
    },
    {
      field: "vatPercent",
      headerName: "Vat Percent",
      flex: 1,
      maxWidth: 180,
      renderCell: (params) => `${params?.value}%`,
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => moment(params.value).format("Do MMM, YYYY"),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  navigate(`${RouteConstant.UPDATE_CHARGES}/${params?.row?.id}`)
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Edit">
                  <EditIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
    {
      field: "seeDetails",
      headerName: "See Details",
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(`${RouteConstant.CHARGES}/${params?.row?.id}`)
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Edit">
                  <Visibility color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box mt={2}>
        <CustomDataGrid
          loading={loading}
          columns={columns}
          rows={data || []}
          count={count}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Box>
    </>
  );
};

export default ChargesTable;
