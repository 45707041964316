import { CreateChargesModel } from "_models/data/charges/data.createcharges.model";
import { isValidEmail, isValidPhone } from "functions/validators";

export const validateCharges = (DATA: CreateChargesModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.chargeName?.trim()) {
    valid = false;
    errors.push({
      name: "chargeName",
      error: "Charge name is required.",
    });
  }

  if (DATA?.charges == undefined || DATA?.charges == null) {
    valid = false;
    errors.push({
      name: "charges",
      error: "Charges is required.",
    });
  }
  if (DATA?.serviceCharges == undefined || DATA?.serviceCharges == null) {
    valid = false;
    errors.push({
      name: "serviceCharges",
      error: "Service Charges is required.",
    });
  }
  if (DATA?.vatPercent == undefined || DATA?.vatPercent == null) {
    valid = false;
    errors.push({
      name: "vatPercent",
      error: "Vat Percent is required.",
    });
  }

  return { valid, errors };
};
