import ProductTableInterface from "_models/data/product/data.product.model";
import { AppStatusCode } from "config/appStatusCode";
import { GetAllProduct } from "functions/http-requests/product";
import { useEffect, useState } from "react";
import ProductTable from "./productTable";
import { ProductFilterModel } from "_models/data/product/data.product-filter.model";
import { GetAllProductsProps } from "_interfaces/functions/http-requests/product";
import { filterNonNullValues } from "functions/helper";
import { Grid } from "@mui/material";
import ProductFilter from "./productFilter";
import { useSearchParams } from "react-router-dom";

export const ProductLayout = () => {
  const [data, setData] = useState<ProductTableInterface["data"]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState(() => {
    const initialFilters: ProductFilterModel = new ProductFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as Record<string, string | number | null>)[key] =
          value || "";
      }
    }
    return initialFilters;
  });

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(filters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [filters]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);

      let PAYLOAD_DATA: GetAllProductsProps["DATA"] = {
        category: filters.category,
      };

      PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetAllProduct({ DATA: PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {
            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              name: item?.name,
              charges: item?.charges,
              productID: item?.productID,
              isActive: item?.isActive,
              whoCanApply: item?.whoCanApply,
              productType: item?.productType,
              productInfo: item?.productInfo,
              externalServiceFor: item?.externalServiceFor,
              createdAt: item?.createdAt,
              subcategory: item?.subcategory,
              category: item?.category,
              isEVerifyRequired: item?.isEVerifyRequired,
              informationRequired: item?.informationRequired,
            }));
            setData(DATA);
          } else {
            setData([]);
          }
        })
        .catch(() => {
          setData([]);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [filters]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
          <ProductFilter
            open={openFilter}
            filters={filters}
            setFilters={setFilters}
            setOpen={setOpenFilter}
          />
        </Grid>
      </Grid>
      <ProductTable data={data} loading={dataLoading} />
    </>
  );
};
