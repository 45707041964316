import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { GridPaginationModel } from "@mui/x-data-grid";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import {
  ExportCustomers,
  GetAllCustomers,
} from "functions/http-requests/customer-management";
import moment from "moment";
import SectionSearch from "parts/sectionSearch";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CustomerManagementTableInterface from "_interfaces/customer-management/customerManagementTable";
import {
  ExportCustomersProps,
  GetAllCustomersProps,
} from "_interfaces/functions/http-requests/customer-management";
import { CustomerManagementFilterModel } from "_models/data/customer-management/data.customer-management-filter.model";
import CustomerManagementFilter from "./customerManagementFilter";
import CustomerManagementTable from "./customerManagementTable";
import { useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import { setAlert } from "state/reducers/alert";
import { ExportCustomerDialog } from "./exportCustomerDialog";
import { toggleLoading } from "state/reducers/loading";

const CustomerManagementLayout = () => {
  const isInitialRender = useRef(true);
  const [date, setDate] = useState({
    fromDate: undefined,
    toDate: undefined,
  });

  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [data, setData] = useState<CustomerManagementTableInterface["data"]>(
    []
  );

  const [refresh, setRefresh] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKey, setSearchKey] = useState<string>(
    searchParams.get("search") || ""
  );
  const dispatch = useDispatch();
  const [count, setCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState(() => {
    const initialFilters: CustomerManagementFilterModel =
      new CustomerManagementFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as Record<string, string | number | null>)[key] =
          value || "";
      }
    }
    return initialFilters;
  });

  const handleOpenDialog = () => setOpenDialog(true);

  const handleRefresh = () => setRefresh(!refresh);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = e.target.value;
    setSearchKey(newSearchKey);
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", newSearchKey);
      return updatedParams;
    });
  };

  const handleSubmitSearch = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", searchKey);
      updatedParams.set("page", "0"); // Reset to the first page on search submit
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);
      return updatedParams;
    });
    isInitialRender.current = false;
    handleRefresh();
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      // Remove the 'searchKey' parameter from the URL if it exists
      updatedParams.delete("search");
      return updatedParams;
    });
  };

  const handleCloseDialog = () => {
    setDate({
      fromDate: undefined,
      toDate: undefined,
    });
    setOpenDialog(false);
  };

  const handleExportCSV = async (dates: any) => {
    dispatch(toggleLoading(true));

    let PAYLOAD_DATA: ExportCustomersProps["DATA"] = {
      fromDate: dates.fromDate,
      toDate: dates.toDate,
    };
    let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);
    try {
      const res = await ExportCustomers({ DATA: FILTERED_PAYLOAD_DATA });

      if (res.status === 200 && res.data) {
        // Access CSV data
        const csvData = res.data;

        // Create a Blob object from the CSV data
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

        // Create a download link
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "exported_data.csv");

        // Append link to the document and trigger a click
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        dispatch(
          setAlert({ type: "success", message: "CSV downloaded successfully!" })
        );
      } else {
        dispatch(
          setAlert({ type: "error", message: "Failed to export CSV data!" })
        );
      }
    } catch (error) {
      dispatch(setAlert({ type: "error", message: "Error in exporting CSV!" }));
    } finally {
      dispatch(toggleLoading(false));
      handleCloseDialog();
    }
  };

  useEffect(() => {
    const search = searchParams.get("search") || "";
    setSearchKey(search);
  }, [searchParams]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(filters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [filters, paginationModel, setSearchParams]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GetAllCustomersProps["DATA"] = {
        searchKey: searchKey,
        kycStatus: filters?.kycStatus || undefined,
        serviceStatus: filters?.serviceStatus || undefined,
        customerType: filters?.customerType || undefined,
        identityType: filters?.identityType || undefined,
        fromDate: filters?.fromDate
          ? moment(filters.fromDate).format("YYYY/MM/DD")
          : undefined,
        toDate: filters?.toDate
          ? moment(filters.toDate).format("YYYY/MM/DD")
          : undefined,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      };

      let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetAllCustomers({
        DATA: FILTERED_PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              MSISDN: item?.MSISDN || "",
              nameAsDocument: item?.nameAsDocument || "",
              identityType: item?.identityType || "",
              kyc_Details: item?.kyc_Details,
              MSISDN_details: item?.MSISDN_details,
              createdAt: item?.createdAt || "",
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [paginationModel, refresh, filters, searchKey]);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (!searchKey) handleRefresh();
    }
  }, [searchKey]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
          <Box sx={{ flex: "1 1 auto" }}>
            <SectionSearch
              name="search_regional_distributor"
              value={searchKey}
              onChange={handleSearch}
              onClear={handleClearSearch}
              onSubmit={handleSubmitSearch}
            />
          </Box>
          <CustomerManagementFilter
            open={openFilter}
            filters={filters}
            setFilters={setFilters}
            setOpen={setOpenFilter}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="tw-flex tw-justify-end">
          <Button
            variant="outlined"
            className="tw-mr-[12px]"
            onClick={handleOpenDialog}
          >
            Export CSV
          </Button>
        </Grid>
      </Grid>

      <ExportCustomerDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        exportData={handleExportCSV}
        date={date}
        setDate={setDate}
      />

      <Box pt={3}>
        <CustomerManagementTable
          data={data}
          loading={dataLoading}
          count={count}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Box>
    </>
  );
};

export default CustomerManagementLayout;
