export interface ProductFilterModelProps {
  category?:string
}

const defaultProductFilterModelProps: ProductFilterModelProps = {
  category: "", 
};

export class ProductFilterModel {
  category?: string ;

  constructor(
    data: ProductFilterModelProps = defaultProductFilterModelProps
  ) {
    this.category = data.category || "";
  }
}
