import { ChargeDetailsLayout } from "component/charges/details";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useParams, Navigate } from "react-router-dom";

export const ChargeDetails = () => {
  const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.INFLUENCER} />;

  return (
    <MainContainer>
      <ChargeDetailsLayout id={params.id} />
    </MainContainer>
  );
};
