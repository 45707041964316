import { GetAllApplicationProps } from "_interfaces/functions/http-requests/applications";
import {
  GET_ALL_COMPANY_APPLICATION,
  GET_COMPANY_APPLICATION_BY_ID,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const GetAllApplication = ({ DATA }: GetAllApplicationProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_COMPANY_APPLICATION,
    Data: DATA,
  });
};

export const GetApplicationDetails = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_COMPANY_APPLICATION_BY_ID(ID),
  });
};
