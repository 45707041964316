import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React from "react";
import { Box, Card, Chip } from "@mui/material";
import { ServiceDetailsInterface } from "_interfaces/service";

interface Props {
  data: ServiceDetailsInterface;
}

const ServiceDetailsTable: React.FC<Props> = ({ data }) => {
  const rows: {
    field: keyof NonNullable<ServiceDetailsInterface>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
    },
    { field: "charges", headerName: "Charges" },
    {
      field: "whoCanApply",
      headerName: "Applicant Criteria",
      renderCell: () => (
        <Box className="tw-flex" sx={{ gap: "5px" }}>
          {data?.whoCanApply?.map((val) => (
            <Chip label={val} />
          ))}
        </Box>
      ),
    },
    {
      field: "serviceType",
      headerName: "Service Type",
      renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
    },
    { field: "serviceInfo", headerName: "Service Info" },
    {
      field: "isActive",
      headerName: "Status",
      renderCell: (v: string) => (
        <Box className="tw-capitalize" sx={{ color: v ? "green" : "red" }}>
          {v ? "Active" : "Inactive"}
        </Box>
      ),
    },
    {
      field: "externalServiceFor",
      headerName: "External Service For",
      renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
    },
    {
      field: "createdAt",
      headerName: "Date",
      renderCell: (params) => moment(params?.value).format("Do MMM, YYYY"),
    },
  ];

  return (
    <>
      <Card
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default ServiceDetailsTable;
