import { UpdateServiceLayout } from "component/service/update/updateService";
import { RouteConstant } from "navigation/constant";
import { useParams, Navigate } from "react-router-dom";

export const UpdateService = () => {
  const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.SERVICE} />;
  return (
    <>
      <UpdateServiceLayout id={params.id} />
    </>
  );
};
