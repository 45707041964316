import {
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { FormInputProps } from "_interfaces/_common/forms";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import {
  isValidString,
  renderInvalidStringErrorText,
} from "functions/validators";
import { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import axios from "axios";
import { validateService } from "./formValidator";
import { ServiceDetailsModel } from "_models/data/service/data.service.model";
import { UpdateServiceProps } from "_interfaces/functions/http-requests/service";
import { UpdateService } from "functions/http-requests/service";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import { generateAutocompleteOptions } from "functions/helper";
import { ServiceErrorModel } from "_models/error/service/error.service.model";

interface InputListProps extends FormInputProps {
  name:
    | "name"
    | "whoCanApply"
    | "charges"
    | "isActive"
    | "serviceType"
    | "serviceInfo";
  order?: number;
}

interface Props {
  editId?: string;
  data: ServiceDetailsModel;
}

const UpdateServiceForm: React.FC<Props> = ({ editId, data }) => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();

  const [state, setState] = useState<ServiceDetailsModel>(data);
  const [errors, setErrors] = useState<ServiceErrorModel>(
    new ServiceErrorModel()
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      if (name === "name" && value) {
        if (!isValidString(value)) {
          return setErrors((prev) => ({
            ...prev,
            [name]: renderInvalidStringErrorText(),
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
      }
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleBlur = (name: string) => {
    if (name === "name") {
      if (
        state[name] &&
        !isValidString(state[name as keyof ServiceDetailsModel] as string)
      ) {
        setErrors((prev) => ({
          ...prev,
          [name]: renderInvalidStringErrorText(),
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
  };

  const handleUpdate = ({ DATA }: UpdateServiceProps) => {
    Dispatch(toggleLoading(true));
    UpdateService({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_updated ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          navigate(RouteConstant.SERVICE);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (): void => {
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateService(state, editId);

    if (ValidateStep?.valid) {
      const DATA: UpdateServiceProps["DATA"] = {
        id: editId as string,
        isActive: state.isActive === "active" ? true : false,
        whoCanApply: state.whoCanApply,
        serviceType: state.serviceType,
        serviceInfo: state.serviceInfo,
        charges: state.charges,
      };

      handleUpdate({ DATA });
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "text",
        name: "name",
        label: "Service Name",
        placeholder: "Enter Service Name",
        grid: 6,
        enabled: true,
        order: 1,
      },
      {
        type: "autoComplete",
        name: "whoCanApply",
        label: "Applicant Criteria",
        placeholder: "Select Applicant Criteria",
        grid: 6,
        order: 2,
        options: generateAutocompleteOptions([
          "company",
          "dealer",
          "sub_dealer",
          "gsm_agent",
          "momo_agent",
          "influencer",
          "merchant",
          "handler",
          "anyone",
        ]),
        multiple: true,
        enabled: true,
      },
      {
        type: "number",
        name: "charges",
        label: "Charges",
        placeholder: "Enter Charges",
        grid: 6,
        enabled: true,
        order: 5,
      },
      {
        type: "autoComplete",
        name: "isActive",
        label: "Status",
        placeholder: "Select Status",
        options: generateAutocompleteOptions(["active", "inActive"]),
        multiple: false,
        grid: 6,
        order: 3,
        enabled: true,
      },
      {
        type: "autoComplete",
        name: "serviceType",
        label: "Service Type",
        placeholder: "Select Service Type",
        options: generateAutocompleteOptions(["external", "internal", "both"]),
        multiple: false,
        order: 4,
        grid: 6,
        enabled: true,
      },
      {
        type: "text",
        name: "serviceInfo",
        label: "Service Info",
        placeholder: "Enter Service Info",
        grid: 6,
        enabled: true,
        order: 6,
      },
    ],
    [editId]
  );

  return (
    <>
      <Box id="crete_edit_distributor_form">
        <Grid container rowSpacing={3} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                order,
                enabled,
                options,
                multiple,
              },
              index
            ) => (
              <Fragment key={index}>
                {enabled ? (
                  <>
                    {type === "text" ||
                    type === "email" ||
                    type === "number" ? (
                      <Grid item xs={12} md={6} lg={grid || 6} order={order}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={
                            state[name as keyof ServiceDetailsModel] == 0
                              ? 0
                              : state[name as keyof ServiceDetailsModel]
                          }
                          errorText={errors[name as keyof ServiceErrorModel]}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={() => handleBlur(name)}
                          disabled={disabled}
                        />
                      </Grid>
                    ) : type === "autoComplete" ? (
                      <Grid item xs={12} lg={grid || 6} order={order}>
                        <InputAutoComplete
                          name={name}
                          options={options || []}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options.length &&
                            state?.[name as keyof ServiceDetailsModel]
                          }
                          errorText={errors[name as keyof ServiceErrorModel]}
                          onFocus={handleFocus}
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UpdateServiceForm;
