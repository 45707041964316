export interface TaskFilterModelProps {
  unassignedOnly?: boolean | string | null;
}

const defaultTaskFilterModelProps: TaskFilterModelProps = {
  unassignedOnly: null,
};

export class TaskFilterModel {
  unassignedOnly?: boolean | string | null;;

  constructor(data: TaskFilterModelProps = defaultTaskFilterModelProps) {
    this.unassignedOnly = data.unassignedOnly || null;
  }
}
