import { Box, Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BlockIcon from '@mui/icons-material/Block';
import { toggleLoading } from "state/reducers/loading";
import { useDispatch } from "react-redux";
import { IInterSwitchEValueCard } from "_interfaces/inter-switch-e-value/InterSwitchEValueCard";
import { GetInterSwitchEValue } from "functions/http-requests/inter-switch-e-value";
import { useState, useEffect } from "react";
import AddBalanceDialog from "./AddBalanceDialog";
import BlockDialog from "./BlockDialog";
import InterSwitchEValueCard from "./InterSwitchEValueCard";
import { InterSwitchRechargeTable } from "./recharge";

const InterSwitchEValueLayout = () => {
    const emptyData: IInterSwitchEValueCard = {
        walletID: "",
        balance: 0,
        isActive: false
    }
    const [data, setData] = useState<IInterSwitchEValueCard>(emptyData);
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [openBlock, setOpenBlock] = useState<boolean>(false);
    const Dispatch = useDispatch();

    const toggleForm = () => {
        setOpenForm(!openForm);
    };
    const toggleBlockForm = () => {
        setOpenBlock(!openBlock);
    };

    const handleClose = () => {
        setOpenForm(false);
    };

    const handleCloseBlockDialog = () => {
        setOpenBlock(false)
    }

    const fetchData: (() => void) | null = () => {
        Dispatch(toggleLoading(true));
        GetInterSwitchEValue().then((res) => {
            const data = res?.data?.data;
            setData(data);
        }).catch(() => {
            setData(emptyData)
        }).finally(() => {
            Dispatch(toggleLoading(false));
        });
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <Grid container spacing={1} justifyContent="flex-end">
                <Grid item xs={12} sm={6}>
                    <Box className="tw-flex tw-justify-end tw-items-center" sx={{ gap: '10px' }}>
                        <Button
                            onClick={toggleForm}
                            variant="outlined"
                            startIcon={<AddIcon />}
                            disableElevation
                        >
                            <Box component="span" className="tw-line-clamp-1">
                                Add Balance
                            </Box>
                        </Button>
                        <Button
                            onClick={toggleBlockForm}
                            variant="outlined"
                            startIcon={<BlockIcon />}
                            disableElevation
                            color={!data.isActive ? "success" : "error"}
                        >
                            <Box component="span" className="tw-line-clamp-1">
                                {!data.isActive ? "Unblock" : "Block"}
                            </Box>
                        </Button>
                    </Box>
                </Grid>
            </Grid>


            <InterSwitchEValueCard data={data} />
            <AddBalanceDialog open={openForm} onClose={handleClose} fetchAllData={fetchData} />
            <BlockDialog open={openBlock} onClose={handleCloseBlockDialog} activation={data?.isActive} fetchAllData={fetchData} />

            {/* Recharge table */}
            <InterSwitchRechargeTable/>
        </>
    )
}

export default InterSwitchEValueLayout;