import { ProductDetailsInterface } from "_interfaces/product";
import { AppStatusCode } from "config/appStatusCode";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import ProductDetailsTable from "./detailsTable";
import { GetProductById } from "functions/http-requests/product";

interface Props {
  id: string;
}

export const ProductDetailsLayout: React.FC<Props> = ({ id }) => {
  const [product, setProduct] = useState<ProductDetailsInterface | null>(null);
  const Dispatch = useDispatch();

  let handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetProductById(ID)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA = data?.data;
          setProduct({
            id: DATA?._id,
            name: DATA?.name,
            charges: DATA?.charges,
            productID: DATA?.productID,
            isActive: DATA?.isActive,
            whoCanApply: DATA?.whoCanApply,
            productType: DATA?.productType,
            productInfo: DATA?.productInfo,
            externalServiceFor: DATA?.externalServiceFor,
            createdAt: DATA?.createdAt,
            informationRequired: DATA?.informationRequired,
            isEVerifyRequired: DATA?.isEVerifyRequired,
            category: DATA?.category,
            subcategory: DATA?.subcategory,
          });
        } else {
          setProduct(null);
        }
      })
      .catch((error) => {
        setProduct(null);
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    handleFetchById(id);
  }, []);
  return <>{product && <ProductDetailsTable data={product} />}</>;
};
