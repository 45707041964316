export interface ApplicationFilterModelProps {
    searchKey?: string; // companyName, applicationID, email, registrationNumber
    MVNO: "TalkioMobile" | "PatriotMobile" | "Fast" | "HFBConnect" | "Simple" | "";
    status?: "idle" | "submitted" | "rejected" | "approved" | "";
    fromDate?: string | null; // YYYY-MM-DD
    toDate?: string | null; // YYYY-MM-DD
  }
  
  export const defaultApplicationFilterModelProps: ApplicationFilterModelProps = {
    searchKey: "",
    MVNO: "",
    status: "",
  };
  
  export class ApplicationFilterModel {
    searchKey?: string;
    MVNO: "TalkioMobile" | "PatriotMobile" | "Fast" | "HFBConnect" | "Simple" | "";
    status?: "idle" | "submitted" | "rejected" | "approved" | "";
    fromDate?: string | null;
    toDate?: string | null;
  
    constructor(data: ApplicationFilterModelProps = defaultApplicationFilterModelProps) {
      this.searchKey = data.searchKey || "";
      this.MVNO = data.MVNO || "";
      this.status = data.status || "";
      this.fromDate = data.fromDate || null;
      this.toDate = data.toDate || null;
    }
  }
  