import { ServiceDetailsLayout } from "component/service/details"
import { RouteConstant } from "navigation/constant";
import { useParams, Navigate } from "react-router-dom";

export const ServiceDetails = () => {
    const params = useParams();

    if (!params?.id) return <Navigate to={RouteConstant.SERVICE} />;
    return (
        <>
            <ServiceDetailsLayout id={params.id} />
        </>
    )
}