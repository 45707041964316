import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";
import CustomDateRangePicker from "component/_common/forms/customDateRangePicker";
import moment from "moment";

interface Props {
  open: boolean;
  date: any;
  setDate: (dates: any) => void;
  handleClose: () => void;
  exportData: (dates: any) => any;
}

export const ExportCustomerDialog: React.FC<Props> = ({
  open,
  handleClose,
  exportData,
  date,
  setDate,
}) => {
  const handleExport = async () => {
    await exportData(date);
    setDate({
      fromDate: undefined,
      toDate: undefined,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Export Customers Data</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <CustomDateRangePicker
            label={"Select Date Range"}
            value={[
              date.fromDate ? moment(date.fromDate).toDate() : null,
              date.toDate ? moment(date.toDate).toDate() : null,
            ]}
            onChange={(newValue: [Date | null, Date | null]) => {
              setDate((prev: any) => ({
                ...prev,
                fromDate: newValue[0]
                  ? moment(newValue[0]).format("YYYY/MM/DD")
                  : null,
                toDate: newValue[1]
                  ? moment(newValue[1]).format("YYYY/MM/DD")
                  : null,
              }));
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleExport} color="primary" variant="contained">
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};
