import Box from "@mui/material/Box";
import { GridColDef } from "@mui/x-data-grid";
import TaskTableInterface from "_interfaces/tasks";
import moment from "moment";
import CustomDataGrid from "parts/customDataGrid";
import React from "react";

const TaskTable: React.FC<TaskTableInterface> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
  setSelectedRows,
}) => {
  const columns: GridColDef[] = [
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      maxWidth: 280,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.value}</Box>
      ),
    },
    {
      field: "teamDetails",
      headerName: "Assigned Team",
      flex: 1,
      maxWidth: 200,
      renderCell: (params) => (
        <Box className="tw-capitalize">
          {!params?.value ? "-" : params?.value?.name}
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      maxWidth: 180,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.formattedValue}</Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 180,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.value}</Box>
      ),
    },
    {
      field: "assignDate",
      headerName: "Assigned Date",
      flex: 1,
      maxWidth: 170,
      renderCell: (params) => (
        <Box className="tw-capitalize">
          {!params?.value ? "-" : moment(params?.value).format("Do MMM, YYYY")}
        </Box>
      ),
    },
    {
      field: "lastUpdateDate",
      headerName: "Last Update Date",
      minWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize">
          {!params?.value ? "-" : moment(params?.value).format("Do MMM, YYYY")}
        </Box>
      ),
    },
  ];

  return (
    <>
      <CustomDataGrid
        loading={loading}
        columns={columns}
        rows={data || []}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        checkboxSelection={true}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

export default TaskTable;
