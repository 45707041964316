import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import axios from "axios";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import {
  filterNonNullValues,
  generateAutocompleteOptions,
} from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "navigation/constant";
import {
  CreateChargesModel,
  CreateChargesModelProps,
} from "_models/data/charges/data.createcharges.model";
import { CreateChargesErrorModel } from "_models/error/charges/error.charges.model";
import { validateCharges } from "./formValidator";
import {
  CreateChargesProps,
  UpdateChargesProps,
} from "_interfaces/functions/http-requests/charges";
import {
  CreateCharges,
  GetChargeDetails,
  UpdateCharges,
} from "functions/http-requests/charges";
import { ChargeDetailsInterface } from "_interfaces/charge";

interface InputListProps extends FormInputProps {
  name:
    | "chargeName"
    | "charges"
    | "serviceCharges"
    | "vatPercent"
    | "managerPhone"
    | "password"
    | "region"
    | "district"
    | "county"
    | "subCounty"
    | "parish"
    | "acknowledgementForm";
}

interface Props {
  data?: ChargeDetailsInterface | null;
  id?: string;
}

const CreateChargesForm: React.FC<Props> = ({ data, id }) => {
  const Dispatch = useDispatch();
  const emptyCharge: CreateChargesModelProps = {
    chargeName: "",
    charges: 0,
    serviceCharges: 0,
    vatPercent: 0,
  };

  const [state, setState] = useState<CreateChargesModel>(emptyCharge);
  const [errors, setErrors] = useState<CreateChargesErrorModel>(
    new CreateChargesErrorModel()
  );
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleCreate = ({ DATA }: CreateChargesProps) => {
    Dispatch(toggleLoading(true));
    CreateCharges({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          navigate(RouteConstant.CHARGES);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };
  const handleUpdate = ({ DATA }: CreateChargesProps) => {
    if (!id) return;
    Dispatch(toggleLoading(true));
    const Payload: UpdateChargesProps = {
      DATA: {
        id: id,
        chargeName: state?.chargeName || "",
        charges: Number(state?.charges) || 0,
        serviceCharges: Number(state?.serviceCharges) || 0,
        vatPercent: Number(state?.vatPercent) || 0,
      },
    };

    UpdateCharges(Payload)
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          navigate(RouteConstant.CHARGES);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateCharges(state);

    if (ValidateStep?.valid) {
      let DATA: CreateChargesProps["DATA"] = {
        chargeName: state?.chargeName || "",
        charges: Number(state?.charges) || 0,
        serviceCharges: Number(state?.serviceCharges) || 0,
        vatPercent: Number(state?.vatPercent) || 0,
      };
      DATA = filterNonNullValues(DATA);
      if (id) {
        handleUpdate({ DATA });
      } else {
        handleCreate({ DATA });
      }
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "chargeName",
        label: "Charge Name",
        placeholder: "Select Charge Name",
        enabled: true,
        grid: 6,
        options: generateAutocompleteOptions([
          "company_application_charge",
          "fixed_number_registration",
          "toll_free_number_registration",
          "broadband_number_registration",
          "sip_number_registration",
        ]),
        multiple: false,
      },
      {
        type: "number",
        name: "charges",
        label: "Charges",
        placeholder: "Enter Charges",
        enabled: true,
        grid: 6,
      },
      {
        type: "number",
        name: "serviceCharges",
        label: "Service Charges",
        placeholder: "Enter Service Charges",
        enabled: true,
        grid: 6,
      },
      {
        type: "number",
        name: "vatPercent",
        label: "Vat Percent",
        placeholder: "Enter Vat Percent",
        enabled: true,
        grid: 6,
      },
    ],
    []
  );

  const fetchCharge = () => {
    if (!id) return;
    Dispatch(toggleLoading(true));
    GetChargeDetails(id)
      .then((res) => {
        const data = res?.data;

        if (data?.statusCode === AppStatusCode.api_success) {
          const DATA: any = data?.data;
          const Data = {
            chargeName: DATA.chargeName,
            charges: DATA?.charges,
            subTotal: DATA?.subTotal,
            serviceCharges: DATA?.serviceCharges,
            vatPercent: DATA?.vatPercent,
          };
          setState(Data);
        }
      })
      .catch((error) => {
        setState(emptyCharge);
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  useEffect(() => {
    fetchCharge();
  }, [id]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      managerEmail: "",
      managerPassword: "",
    }));
  }, []);

  return (
    <>
      <Box
        component="form"
        id="crete_edit_influencer_form"
        onSubmit={handleSubmit}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                InputProps,
                multiline,
                enabled,
                options,
                multiple,
              },
              index
            ) => (
              <Fragment key={index}>
                {enabled ? (
                  <>
                    {type === "text" ||
                    type === "email" ||
                    type === "number" ? (
                      <Grid item xs={12} sm={6} md={grid || 12}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof CreateChargesModel]}
                          errorText={
                            errors[name as keyof CreateChargesErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                          InputProps={InputProps}
                          multiline={multiline}
                        />
                      </Grid>
                    ) : type === "autoComplete" ? (
                      <Grid item xs={12} md={grid || 12}>
                        <InputAutoComplete
                          name={name}
                          options={options || []}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options?.length &&
                            state?.[name as keyof CreateChargesModel]
                          }
                          errorText={
                            errors[name as keyof CreateChargesErrorModel]
                          }
                          onFocus={handleFocus}
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              {id ? "Update Charges" : "Create Charges"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateChargesForm;
