import { RechargeDetailsLayout } from "component/recharge/details";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useParams, Navigate } from "react-router-dom";

export const InterSwitchRechargeDetails = () => {
  const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.RECHARGES} />;

  return (
    <MainContainer>
      <RechargeDetailsLayout id={params.id} />
    </MainContainer>
  );
};
