import React from "react";
import { IconsInterface } from "_interfaces/_common/icons";

const ApplicationIcon: React.FC<IconsInterface> = ({
  width,
  height,
  color,
}) => {
  return (
    <svg
      width={width || "18"}
      height={height || "21"}
      viewBox="0 0 640 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M432 64L208 64c-8.8 0-16 7.2-16 16l0 16-64 0 0-16c0-44.2 35.8-80 80-80L432 0c44.2 0 80 35.8 80 80l0 224c0 44.2-35.8 80-80 80l-16 0 0-64 16 0c8.8 0 16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zM0 192c0-35.3 28.7-64 64-64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 192zm64 32c0 17.7 14.3 32 32 32l192 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 192c-17.7 0-32 14.3-32 32z"
        fill={color}
      />
    </svg>
  );
};

export default ApplicationIcon;