import { GridPaginationModel } from "@mui/x-data-grid";
import { GetAllApplicationProps } from "_interfaces/functions/http-requests/applications";
import { ApplicationFilterModel } from "_models/data/application/data.application-filter.model";
import ApplicationTableInterface from "_models/data/application/data.application.model";
import ChargesTableInterface from "_models/data/charges/data.charges.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetAllCharges } from "functions/http-requests/charges";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ChargesTable from "./chargesTable";
import { Add } from "@mui/icons-material";
import { Grid, Box, Button } from "@mui/material";
import { RouteConstant } from "navigation/constant";

export const ChargesLayout = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<ChargesTableInterface["data"]>([]);
  const isInitialRender = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKey, setSearchKey] = useState<string>(
    searchParams.get("search") || ""
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const handleRefresh = () => setRefresh(!refresh);
  const handleClearSearch = () => {
    setSearchKey("");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      // Remove the 'searchKey' parameter from the URL if it exists
      updatedParams.delete("search");
      return updatedParams;
    });
  };
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  });
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState(() => {
    const initialFilters: ApplicationFilterModel = new ApplicationFilterModel();
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page" && key !== "pageSize" && key !== "search") {
        (initialFilters as unknown as Record<string, string | number | null>)[
          key
        ] = value || "";
      }
    }
    return initialFilters;
  });
  const [count, setCount] = useState<number>(0);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKey = e.target.value;
    setSearchKey(newSearchKey);
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", newSearchKey);
      return updatedParams;
    });
  };

  const handleSubmitSearch = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("search", searchKey);
      updatedParams.set("page", "0"); // Reset to the first page on search submit
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);
      return updatedParams;
    });
    isInitialRender.current = false;
    handleRefresh();
  };

  useEffect(() => {
    const search = searchParams.get("search") || "";
    setSearchKey(search);
  }, [searchParams]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);

      updatedParams.set("page", `${paginationModel.page}`);
      updatedParams.set("pageSize", `${paginationModel.pageSize}`);

      // If the filters object is empty, clear all filter parameters from the URL
      if (Object.keys(filters).length === 0) {
        // Remove all filter-related keys from the URL
        Array.from(updatedParams.keys()).forEach((key) => {
          if (key !== "search" && key !== "page" && key !== "pageSize") {
            updatedParams.delete(key);
          }
        });
      } else {
        // Update filters in the URL
        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            updatedParams.set(key, String(value));
          } else {
            updatedParams.delete(key);
          }
        });
      }

      return updatedParams;
    });
  }, [filters, paginationModel, setSearchParams]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);

      GetAllCharges()
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count[0]?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              chargeName: item?.chargeName,
              charges: item?.charges,
              subTotal: item?.subTotal,
              serviceCharges: item?.serviceCharges,
              vatPercent: item?.vatPercent,
              createdAt: item?.createdAt,
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [refresh, paginationModel, filters, searchKey]);

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Box className="tw-flex tw-justify-end tw-items-center" px={2} py={2}>
            <Button
              startIcon={<Add />}
              type="submit"
              variant="outlined"
              disableElevation
              className="tw-capitalize tw-py-[8px]"
              onClick={() => navigate(RouteConstant.CREATE_CHARGES)}
            >
              Create Charges
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ChargesTable data={data} />;
    </>
  );
};
