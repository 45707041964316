export interface CreateChargesErrorModelProps {
  chargeName: string;
  charges: string;
  serviceCharges: string;
  vatPercent: string;
}

export const defaultCreateChargesErrorModelProps: CreateChargesErrorModelProps =
  {
    chargeName: "",
    charges: "",
    serviceCharges: "",
    vatPercent: "",
  };

export class CreateChargesErrorModel {
  chargeName: string;
  charges: string;
  serviceCharges: string;
  vatPercent: string;

  constructor(
    data: CreateChargesErrorModelProps = defaultCreateChargesErrorModelProps
  ) {
    this.chargeName = data.chargeName;
    this.charges = data.charges;
    this.serviceCharges = data.serviceCharges;
    this.vatPercent = data.vatPercent;
  }
}
