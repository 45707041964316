import { ServiceDetailsModel } from "_models/data/service/data.service.model";

export const validateService = (
  DATA: ServiceDetailsModel,
  editId?: string | number | null
) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.name) {
    valid = false;
    errors.push({
      name: "name",
      error: "Service name is required.",
    });
  }

  if (!DATA?.isActive) {
    valid = false;
    errors.push({
      name: "isActive",
      error: "Active status is required.",
    });
  }

  if (!DATA?.whoCanApply || DATA?.whoCanApply?.length == 0) {
    valid = false;
    errors.push({
      name: "whoCanApply",
      error: "Applicant criteria is required.",
    });
  }

  if (!DATA?.serviceType) {
    valid = false;
    errors.push({
      name: "serviceType",
      error: "Service type is required.",
    });
  }
  if (!DATA?.serviceInfo) {
    valid = false;
    errors.push({
      name: "serviceInfo",
      error: "Service information is required.",
    });
  }
  if (DATA?.charges == undefined || DATA?.charges == null) {
    valid = false;
    errors.push({
      name: "charges",
      error: "Charges is required.",
    });
  }
  if (DATA?.charges < 0) {
    valid = false;
    errors.push({
      name: "charges",
      error: "Charges cannot be negative.",
    });
  }

  return { valid, errors };
};
