import DataGridTableInterface from "_interfaces/_common/dataGridTable";

export interface ServiceError {
  id: string;
  name: string;
  isActive: string;
  whoCanApply: string;
  charges: string;
  serviceType: string;
  serviceInfo: string;
}

const defaultServiceDetails: ServiceError = {
  id: "",
  name: "",
  isActive: "",
  whoCanApply: "",
  charges: "",
  serviceType: "",
  serviceInfo: "",
};

export class ServiceErrorModel {
  id: string;
  name: string;
  isActive: string;
  whoCanApply: string;
  charges: string;
  serviceType: string;
  serviceInfo: string;

  constructor(data: ServiceError = defaultServiceDetails) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.isActive = data.isActive;
    this.whoCanApply = data.whoCanApply;
    this.charges = data.charges;
    this.serviceType = data.serviceType;
    this.serviceInfo = data.serviceInfo;
  }
}
