export interface ProductError {
  id: string;
  name: string;
  isActive: string;
  whoCanApply: string;
  charges: string;
  serviceType: string;
  serviceInfo: string;
  category: string;
}

const defaultProductDetails: ProductError = {
  id: "",
  name: "",
  isActive: "",
  whoCanApply: "",
  charges: "",
  serviceType: "",
  serviceInfo: "",
  category: "",
};

export class ProductErrorModel {
  id: string;
  name: string;
  isActive: string;
  whoCanApply: string;
  charges: string;
  serviceType: string;
  serviceInfo: string;
  category: string;

  constructor(data: ProductError = defaultProductDetails) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.isActive = data.isActive;
    this.whoCanApply = data.whoCanApply;
    this.charges = data.charges;
    this.serviceType = data.serviceType;
    this.serviceInfo = data.serviceInfo;
    this.category = data.category;
  }
}
