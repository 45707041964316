import { CompanyApplicationDetailsLayout } from "component/applications/details";
import { RouteConstant } from "navigation/constant";
import { useParams, Navigate } from "react-router-dom";

export const CompanyApplicationDetails = () => {
  const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.APPLICATIONS} />;
  return <CompanyApplicationDetailsLayout id={params.id} />;
};
