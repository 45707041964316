import { UpdateServiceProps } from "_interfaces/functions/http-requests/service";
import {
  GET_ALL_SERVICE,
  GET_SERVICE_BY_ID,
  UPDATE_SERVICE,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const GetAllService = () => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_SERVICE,
  });
};

export const GetServiceById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_SERVICE_BY_ID(ID),
  });
};

export const UpdateService = ({ DATA }: UpdateServiceProps) => {
  return HTTP({
    Method: "POST",
    Url: UPDATE_SERVICE,
    Data: DATA,
  });
};
