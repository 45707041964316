import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React from "react";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import RechargeTableInterface from "_interfaces/recharge";

const RechargeTable: React.FC<RechargeTableInterface> = ({
  type,
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "rechargeType",
      headerName: "Recharge Type",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params.value}</Box>
      ),
    },
    {
      field: "rechargeBy",
      headerName: "Recharge By",
      flex: 1,
      maxWidth: 180,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params.value}</Box>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => (params?.value ? params?.value : "-"),
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      maxWidth: 280,
      renderCell: (params) => moment(params.value).format("MMM D YYYY h:mm A"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params.value}</Box>
      ),
    },
    {
      field: "MSISDN",
      headerName: "MSISDN",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(
                    `${
                      type && type === "interswitch"
                        ? RouteConstant.INTER_SWITCH_E_VALUE_RECHARGE
                        : RouteConstant.RECHARGES
                    }/${params?.row?.id}`
                  )
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="View Details">
                  <VisibilityIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box mt={2}>
        <CustomDataGrid
          loading={loading}
          columns={columns}
          rows={data || []}
          count={count}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Box>
    </>
  );
};

export default RechargeTable;
