import { generateAutocompleteOptions } from "functions/helper";
import React, { useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { CustomFilter } from "parts/customFilter";
import { ApplicationFilterModel } from "_models/data/application/data.application-filter.model";

interface InputListProps extends FormInputProps {
  name: "status" | "MVNO" | "dateRange";
}
interface Props extends SideDrawerProps {
  filters: ApplicationFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<ApplicationFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ApplicationFilter: React.FC<Props> = ({
  open,
  filters,
  setFilters,
  setOpen,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "status",
        label: "Filter by Status",
        placeholder: "Select Status",
        options: generateAutocompleteOptions([
          "idle",
          "submitted",
          "rejected",
          "approved",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "MVNO",
        label: "Filter by MVNO",
        placeholder: "Select MVNO",
        options: generateAutocompleteOptions([
          "TalkioMobile",
          "PatriotMobile",
          "Fast",
          "HFBConnect",
          "Simple",
        ]),
        multiple: false,
      },
      {
        type: "dateRange",
        name: "dateRange",
        label: "Select Date range",
        placeholder: "Select Date range",
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default ApplicationFilter;
