import {
  CreateChargesProps,
  UpdateChargesProps,
} from "_interfaces/functions/http-requests/charges";
import {
  Create_CHARGES,
  GET_ALL_CHARGES,
  GET_CHARGES_BY_ID,
  Update_CHARGES,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const GetAllCharges = () => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_CHARGES,
  });
};

export const GetChargeDetails = (ID:string) => {
  return HTTP({
    Method: "GET",
    Url: GET_CHARGES_BY_ID(ID),
  });
};

export const CreateCharges = ({ DATA }: CreateChargesProps) => {
  return HTTP({
    Method: "POST",
    Url: Create_CHARGES,
    Data: DATA,
  });
};

export const UpdateCharges = ({ DATA }: UpdateChargesProps) => {
  return HTTP({
    Method: "POST",
    Url: Update_CHARGES,
    Data: DATA,
  });
};
