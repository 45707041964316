import { AppStatusCode } from "config/appStatusCode";
import { GetServiceById } from "functions/http-requests/service";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import UpdateServiceForm from "./updateServiceForm";
import { ServiceDetailsModel } from "_models/data/service/data.service.model";
import { Card } from "@mui/material";

interface Props {
  id: string;
}

export const UpdateServiceLayout: React.FC<Props> = ({ id }) => {
  const [service, setService] = useState<ServiceDetailsModel | null>(null);
  const Dispatch = useDispatch();

  let handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetServiceById(ID)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA = data?.data;
          setService({
            _id: DATA?._id,
            name: DATA?.name,
            charges: DATA?.charges,
            serviceID: DATA?.serviceID,
            isActive: DATA?.isActive ? "active" : "inactive",
            whoCanApply: DATA?.whoCanApply,
            serviceType: DATA?.serviceType,
            serviceInfo: DATA?.serviceInfo,
            externalServiceFor: DATA?.externalServiceFor,
            createdAt: DATA?.createdAt,
          });
        } else {
          setService(null);
        }
      })
      .catch((error) => {
        setService(null);
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    handleFetchById(id);
  }, []);
  return (
    <>
      {service && (
        <Card sx={{ p: 2 }}>
          <UpdateServiceForm data={service} editId={id} />
        </Card>
      )}
    </>
  );
};
