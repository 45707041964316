import { generateAutocompleteOptions } from "functions/helper";
import React, { useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { CustomFilter } from "parts/customFilter";
import { TaskFilterModel } from "_models/data/tasks/data.task-filter.model";

interface InputListProps extends FormInputProps {
  name: "unassignedOnly";
}
interface Props extends SideDrawerProps {
  filters: TaskFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<TaskFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaskFilter: React.FC<Props> = ({
  open,
  filters,
  setFilters,
  setOpen,
}) => {
  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "unassignedOnly",
        label: "Filter by Status",
        placeholder: "Select Status",
        options: generateAutocompleteOptions(["assigned", "unassigned"]),
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default TaskFilter;
