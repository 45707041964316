import { Box, Card, Typography } from "@mui/material";
import { AgentDetailsInterface } from "_interfaces/agent";
import { ChargeDetailsInterface } from "_interfaces/charge";
import { AssignedLocationProps } from "_interfaces/functions/http-requests/agents";
import { RegionalDistributorsTableRowsInterface } from "_interfaces/regional-distributors/regionalDistributorsTable";
import UserActivation from "component/_common/userActivation";
import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React from "react";

interface Props {
  DATA: ChargeDetailsInterface;
}

const ChargeDetailsTable: React.FC<Props> = ({ DATA }) => {
  const rows: {
    field: keyof NonNullable<ChargeDetailsInterface>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    { field: "chargeName", headerName: "Charge Name" },
    {
      field: "charges",
      headerName: "Charges",
      renderCell: () => Number(DATA?.charges).toLocaleString(),
    },
    {
      field: "subTotal",
      headerName: "Sub Total",
      renderCell: () => Number(DATA?.subTotal).toLocaleString(),
    },
    {
      field: "serviceCharges",
      headerName: "Service Charges",
      renderCell: () => Number(DATA?.serviceCharges).toLocaleString(),
    },
    {
      field: "vatPercent",
      headerName: "Vat Charges(%)",
      renderCell: () => `${DATA?.vatPercent}%`,
    },
    {
      field: "createdAt",
      headerName: "Time",
      renderCell: (v: string) => moment(v).format("MMM D YYYY h:mm A"),
    },
  ];

  return (
    <Card
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <DetailsBox data={DATA} rows={rows} />
    </Card>
  );
};

export default ChargeDetailsTable;
