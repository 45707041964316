export interface UploadMsisdnModelProps {
  batchNo?: string;
  firstMSISDN?: string;
  lastMSISDN?: string;
  type?: string;
  category?: string;
  MVNO?: string;
}

const defaultUploadMsisdnModelProps: UploadMsisdnModelProps = {
  batchNo: "",
  firstMSISDN: "",
  lastMSISDN: "",
  type: "",
  category: "",
  MVNO: "",
};

export class UploadMsisdnModel {
  batchNo?: string;
  firstMSISDN?: string;
  lastMSISDN?: string;
  type?: string;
  category?: string;
  MVNO?: string;

  constructor(data: UploadMsisdnModelProps = defaultUploadMsisdnModelProps) {
    this.batchNo = data.batchNo || "";
    this.firstMSISDN = data.firstMSISDN || "";
    this.lastMSISDN = data.lastMSISDN || "";
    this.type = data.type || "";
    this.category = data.category || "";
    this.MVNO = data.MVNO || "";
  }
}
