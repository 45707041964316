import ServiceTableInterface from "_models/data/service/data.service.model";
import { AppStatusCode } from "config/appStatusCode";
import { GetAllService } from "functions/http-requests/service";
import { useEffect, useState } from "react";
import ServiceTable from "./serviceTable";

export const ServiceLayout = () => {
  const [data, setData] = useState<ServiceTableInterface["data"]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);

      GetAllService()
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {
            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              name: item?.name,
              charges: item?.charges,
              serviceID: item?.serviceID,
              isActive: item?.isActive,
              whoCanApply: item?.whoCanApply,
              serviceType: item?.serviceType,
              serviceInfo: item?.serviceInfo,
              externalServiceFor: item?.externalServiceFor,
              createdAt: item?.createdAt,
            }));
            setData(DATA);
          } else {
            setData([]);
          }
        })
        .catch(() => {
          setData([]);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, []);

  return (
    <>
      <ServiceTable data={data} loading={dataLoading} />
    </>
  );
};
