import {
  EXPORT_CUSTOMERS,
  GET_ALL_CUSTOMERS,
  GET_CUSTOMER_BY_AGENT_ID,
  GET_CUSTOMER_BY_DISTRIBUTOR_ID,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_COUNT_BY_DISTRIUTOR,
  GET_CUSTOMER_DOC,
} from "config/endpoint";
import { HTTP } from "functions/http";
import {
  ExportCustomersProps,
  GetAllCustomersByAgentProps,
  GetAllCustomersByDistributorProps,
  GetAllCustomersProps,
} from "_interfaces/functions/http-requests/customer-management";

export const GetAllCustomers = ({ DATA }: GetAllCustomersProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_CUSTOMERS,
    Data: DATA,
  });
};

export const GetCustomerById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_CUSTOMER_BY_ID(ID),
  });
};
export const GetCustomerByDistributorId = ({
  DATA,
}: GetAllCustomersByDistributorProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_CUSTOMER_BY_DISTRIBUTOR_ID,
    Data: DATA,
  });
};
export const GetCustomerByAgentId = ({ DATA }: GetAllCustomersByAgentProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_CUSTOMER_BY_AGENT_ID,
    Data: DATA,
  });
};

export const GetCustomerDoc = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_CUSTOMER_DOC(ID),
  });
};
export const GETCUSTOMERCOUNTBYDISTRIUTOR = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_CUSTOMER_COUNT_BY_DISTRIUTOR(ID),
  });
};

export const ExportCustomers = ({ DATA }: ExportCustomersProps) => {
  return HTTP({
    Method: "POST",
    Url: EXPORT_CUSTOMERS,
    Data: DATA,
  });
};
