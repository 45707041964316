import { ProductDetailsModel } from "_models/data/product/data.product.model";

export const validateService = (
  DATA: ProductDetailsModel,
  editId?: string | number | null
) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.name) {
    valid = false;
    errors.push({
      name: "name",
      error: "Product name is required.",
    });
  }
  if (!DATA.category) {
    valid = false;
    errors.push({
      name: "category",
      error: "Category is required.",
    });
  }

  if (!DATA?.isActive) {
    valid = false;
    errors.push({
      name: "isActive",
      error: "Active status is required.",
    });
  }

  if (!DATA?.whoCanApply || DATA?.whoCanApply?.length == 0) {
    valid = false;
    errors.push({
      name: "whoCanApply",
      error: "Applicant criteria is required.",
    });
  }

  if (!DATA?.productType) {
    valid = false;
    errors.push({
      name: "productType",
      error: "Product type is required.",
    });
  }
  if (!DATA?.productInfo) {
    valid = false;
    errors.push({
      name: "productInfo",
      error: "Product information is required.",
    });
  }
  if (DATA?.charges == undefined || DATA?.charges == null) {
    valid = false;
    errors.push({
      name: "charges",
      error: "Charges is required.",
    });
  }
  if (DATA?.charges < 0) {
    valid = false;
    errors.push({
      name: "charges",
      error: "Charges cannot be negative.",
    });
  }

  return { valid, errors };
};
