import { ApplicationDetailsInterface } from "_interfaces/application";
import { AppStatusCode } from "config/appStatusCode";
import { GetApplicationDetails } from "functions/http-requests/applications";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import ApplicationDetailsTable from "./detailsTable";

interface Props {
  id: string;
}

export const CompanyApplicationDetailsLayout: React.FC<Props> = ({ id }) => {
  const [details, setDetails] = useState<ApplicationDetailsInterface | null>(
    null
  );
  const Dispatch = useDispatch();

  let handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetApplicationDetails(ID)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA = data?.data?.application;
          setDetails({
            id: DATA?._id,
            MVNO: DATA?.MVNO,
            status: DATA?.status,
            registrationDate: DATA?.registrationDate,
            registrationNumber: DATA?.registrationNumber,
            applicationID: DATA?.applicationID,
            URSB_fetched: DATA?.URSB_fetched,
            IndustrySector: DATA?.IndustrySector,
            TIN_number: DATA?.TIN_number,
            businessType: DATA?.businessType,
            companyName: DATA?.companyName,
            phone: DATA?.phone,
            physicalAddress: DATA?.physicalAddress,
            website: DATA?.website,
            email: DATA?.email,
          });
        } else {
          setDetails(null);
        }
      })
      .catch((error) => {
        setDetails(null);
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    handleFetchById(id);
  }, []);
  return <ApplicationDetailsTable data={details} />;
};
