import { ChargeDetailsInterface } from "_interfaces/charge";
import { AppStatusCode } from "config/appStatusCode";
import { GetChargeDetails } from "functions/http-requests/charges";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import ChargeDetailsTable from "./detailsTable";

interface Props {
  id: string;
}

export const ChargeDetailsLayout: React.FC<Props> = ({ id }) => {
  const [details, setDetails] = useState<ChargeDetailsInterface | null>(null);
  const Dispatch = useDispatch();
  const fetchCharge = () => {
    if (!id) return;
    Dispatch(toggleLoading(true));
    GetChargeDetails(id)
      .then((res) => {
        const data = res?.data;

        if (data?.statusCode === AppStatusCode.api_success) {
          const DATA: any = data?.data;
          const Data = {
            id: DATA?._id,
            chargeName: DATA?.chargeName,
            charges: DATA?.charges,
            subTotal: DATA?.subTotal,
            serviceCharges: DATA?.serviceCharges,
            vatPercent: DATA?.vatPercent,
            createdAt: DATA?.createdAt,
          };
          setDetails(Data);
        }
      })
      .catch((error) => {
        setDetails(null);
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  useEffect(() => {
    fetchCharge();
  }, [id]);
  return <>{details && <ChargeDetailsTable DATA={details} />}</>;
};
