import { CreateChargesLayout } from "component/charges/create";
import MainContainer from "parts/mainContainer";

export const CreateCharges = () => {

  return (
    <MainContainer>
      <CreateChargesLayout />
    </MainContainer>
  );
};
