import { Card } from "@mui/material";
import CreateChargesForm from "./createChargesForm";

interface Props {
  id?: string;
}

export const CreateChargesLayout: React.FC<Props> = ({ id }) => {

  return (
    <>
      <Card
        sx={{
          padding: "30px",
        }}
      >
        <CreateChargesForm id={id} />
      </Card>
    </>
  );
};
