import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React from "react";
import { Box, Card } from "@mui/material";
import { ApplicationDetailsInterface } from "_interfaces/application";

interface Props {
  data: ApplicationDetailsInterface | null;
}

const ApplicationDetailsTable: React.FC<Props> = ({ data }) => {
  const rows: {
    field: keyof NonNullable<ApplicationDetailsInterface>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    { field: "MVNO", headerName: "MVNO" },
    { field: "email", headerName: "Email" },
    {
      field: "status",
      headerName: "Status",
      renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
    },
    {
      field: "applicationID",
      headerName: "Application ID",
    },
    {
      field: "URSB_fetched",
      headerName: "URSB",
      renderCell: (v: string) => (
        <Box className="tw-capitalize">{v ? "Fetched" : "Not Fetched"}</Box>
      ),
    },
    {
      field: "IndustrySector",
      headerName: "Industry Sector",
    },
    {
      field: "TIN_number",
      headerName: "TIN Number",
    },
    {
      field: "companyName",
      headerName: "Company Name",
    },
    {
      field: "phone",
      headerName: "Phone Number",
    },
    {
      field: "physicalAddress",
      headerName: "Physical Address",
    },
    {
      field: "businessType",
      headerName: "Business Type",
      renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
    },
    {
      field: "registrationDate",
      headerName: "Registration Date",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },
    {
      field: "registrationNumber",
      headerName: "Registration Number",
    },
    {
      field: "website",
      headerName: "Website",
    },
  ];
  return (
    <>
      <Card
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default ApplicationDetailsTable;
