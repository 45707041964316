import { GetAllApplicationProps } from "_interfaces/functions/http-requests/applications";
import { GET_ALL_COMPANY } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetAllCompany = ({ DATA }: GetAllApplicationProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_COMPANY,
    Data: DATA,
  });
};
