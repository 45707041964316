import { generateAutocompleteOptions } from "functions/helper";
import React, { useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { CustomFilter } from "parts/customFilter";
import { ProductFilterModel } from "_models/data/product/data.product-filter.model";

interface InputListProps extends FormInputProps {
  name: "category";
}
interface Props extends SideDrawerProps {
  filters: ProductFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<ProductFilterModel>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductFilter: React.FC<Props> = ({
  open,
  filters,
  setFilters,
  setOpen,
}) => {

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "category",
        label: "Filter by Category",
        placeholder: "Select Category",
        options: generateAutocompleteOptions([
          "innovationAndTechnology",
          "specializedTelecom",
          "connectivity",
          "cloudAndHosting",
          "managedServices",
          "messagingAndMarketing",
          "industrySolutions",
          "sustainability",
          "financialServices",
          "businessEnablement",
          "innovationAndTechnology",
          "voiceAndCommunication",
        ]),
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default ProductFilter;
