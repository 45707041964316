import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import ServiceTableInterface from "_models/data/service/data.service.model";
import { Visibility } from "@mui/icons-material";
import { toTitleCase } from "functions/helper";

const ServiceTable: React.FC<ServiceTableInterface> = ({ data, loading }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      maxWidth: 170,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params.value}</Box>
      ),
    },
    {
      field: "serviceType",
      headerName: "Service Type",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params.value}</Box>
      ),
    },
    {
      field: "whoCanApply",
      headerName: "Applicant Criteria",
      flex: 1,
      minWidth: 250,
      renderCell: (params) =>
        Array?.isArray(params?.value) && params?.value?.length ? (
          <Tooltip title={params?.value?.join(", ")}>
            <Box className="tw-capitalize tw-truncate">
              {toTitleCase(params?.value?.join(", "))}{" "}
            </Box>
          </Tooltip>
        ) : (
          <></>
        ),
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => (
        <Box
          className="tw-capitalize"
          sx={{ color: params.value ? "green" : "red" }}
        >
          {params.value ? "Active" : "InActive"}
        </Box>
      ),
    },
    {
      field: "charges",
      headerName: "Charges",
      flex: 1,
      maxWidth: 100,
    },
    {
      field: "serviceInfo",
      headerName: "Service Info",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "seeDetails",
      headerName: "See Details",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(`${RouteConstant.SERVICE}/${params?.row?.id}`)
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Edit">
                  <Visibility color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(`${RouteConstant.UPDATE_SERVICE}/${params?.row?.id}`)
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Update Service">
                  <EditIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box mt={2}>
        <CustomDataGrid loading={loading} columns={columns} rows={data || []} />
      </Box>
    </>
  );
};

export default ServiceTable;
