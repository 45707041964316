import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { toTitleCase } from "functions/helper";
import ProductTableInterface from "_models/data/product/data.product.model";

const ProductTable: React.FC<ProductTableInterface> = ({ data, loading }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params.value}</Box>
      ),
    },
    {
      field: "productType",
      headerName: "Product Type",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params.value}</Box>
      ),
    },
    {
      field: "whoCanApply",
      headerName: "Applicant Criteria",
      flex: 1,
      minWidth: 200,
      renderCell: (params) =>
        Array?.isArray(params?.value) && params?.value?.length ? (
          <Tooltip title={params?.value?.join(", ")}>
            <Box className="tw-capitalize tw-truncate">
              {toTitleCase(params?.value?.join(", "))}{" "}
            </Box>
          </Tooltip>
        ) : (
          <></>
        ),
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Box
          className="tw-capitalize"
          sx={{ color: params.value ? "green" : "red" }}
        >
          {params.value ? "Active" : "InActive"}
        </Box>
      ),
    },
    {
      field: "charges",
      headerName: "Charges",
      flex: 1,
      maxWidth: 100,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={params?.value}>
          <Box className="tw-capitalize">{params.value}</Box>
        </Tooltip>
      ),
    },
    {
      field: "productInfo",
      headerName: "Product Info",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "seeDetails",
      headerName: "See Details",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(`${RouteConstant.PRODUCT}/${params?.row?.id}`)
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Edit">
                  <Visibility color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(`${RouteConstant.UPDATE_PRODUCT}/${params?.row?.id}`)
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Update Service">
                  <EditIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box mt={2}>
        <CustomDataGrid loading={loading} columns={columns} rows={data || []} />
      </Box>
    </>
  );
};

export default ProductTable;
