import { CreateChargesLayout } from "component/charges/create";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useParams, Navigate } from "react-router-dom";

export const UpdateCharges = () => {
  const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.CHARGES} />;

  return (
    <MainContainer>
      <CreateChargesLayout id={params.id} />
    </MainContainer>
  );
};
