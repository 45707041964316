import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React from "react";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import ApplicationTableInterface from "_models/data/application/data.application.model";

const ApplicationTable: React.FC<ApplicationTableInterface> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "MVNO",
      headerName: "MVNO",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params.value}</Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 180,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params.value}</Box>
      ),
    },
    {
      field: "businessType",
      headerName: "Business Type",
      flex: 1,
      maxWidth: 180,
      renderCell: (params) => params?.value ? params?.value : "-"
    },
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      maxWidth: 180,
      renderCell: (params) => params?.value ? params?.value : "-"
    },
    {
      field: "registrationDate",
      headerName: "Registration Date",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => moment(params.value).format("Do MMM, YYYY"),
    },
    {
      field: "seeDeatils",
      headerName: "See Details",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(`${RouteConstant.APPLICATIONS}/${params?.row?.id}`)
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="View Details">
                  <VisibilityIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box mt={2}>
        <CustomDataGrid
          loading={loading}
          columns={columns}
          rows={data || []}
          count={count}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Box>
    </>
  );
};

export default ApplicationTable;
